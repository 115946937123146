import React, {Component} from 'react'
import Helmet from 'react-helmet'

import Header from './Header'

import './Legal.css'

class Legal extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div id="legal-container">
        <Helmet>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
        <Header title="Mentions légales" />
        <main>
          <section>
            <p>Mentions légales réglementées par la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés et la loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’Economie Numérique.</p>
            <h2>Confidentialite des données personnelles</h2>
            <p>Vos données personnelles (nom, prénom, adresse, code postal, ville, e-mail, téléphone) sont collectées dans le strict respect de la loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’Economie Numérique.</p>
            <p>Conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés modifiée par la nouvelle loi Informatique et Libertés du 6 août 2004, vous disposez d’un droit d’accès, de modifications, de rectifications et de suppression des données qui vous concernent. Pour cela il vous suffit de nous contacter par courrier, par e-mail ou par téléphone.</p>
            <h2>Nos coordonnées</h2>
            <p>La Brewlangerie<br />306 avenue de Muret<br />31300 Toulouse</p>
            <p>E-mail : <a href="mailto:contact@labrewlangerie.com">contact@labrewlangerie.com</a></p>
            <p>SIRET : 851 624 239 00048</p>
            <h3>Editeurs du site</h3>
            <p>Développement : <a href="https://antoine.rousseau.im">Antoine Rousseau</a></p>
            <p>Graphisme : <a href="https://www.eloisejulien.com">Eloïse Julien</a></p>
            <p>Hébergement : <a href="https://firebase.google.com/">Google Firebase</a> et <a href="https://www.online.net/fr">Online</a></p>
          </section>
          <section>
            <h2>Conditions générales de vente</h2>
            <p>Les présentes conditions de vente sont conclues entre La Brewlangerie, SAS, SIRET 851 624 239 00048, et par toute personne physique ou morale souhaitant procéder à un achat via ce site Internet, dénommée ci-après &laquo; l’acheteur &raquo;.</p>
            <h3>Objet</h3>
            <p>Les présentes conditions de vente visent à définir les relations contractuelles entre La Brewlangerie et l’acheteur et les conditions applicables à tout achat effectué par le biais du site marchand de La Brewlangerie, que l’acheteur soit professionnel ou consommateur.</p>
            <p>L’acquisition d’un bien ou d’un service à travers le présent site implique une acceptation sans réserve par l’acheteur des présentes conditions de vente. Ces conditions de vente prévaudront sur toutes autres conditions générales ou particulières non expressément agréées par La Brewlangerie.</p>
            <p>La Brewlangerie se réserve de pouvoir modifier ses conditions de vente à tout moment. Dans ce cas, les conditions applicables seront celles en vigueur à la date de la commande par l’acheteur.</p>
            <h3>Caractéristiques des biens et services proposés</h3>
            <p>Les produits et services offerts sont ceux qui figurent dans le catalogue publié sur ce site. Ces produits et services sont offerts dans la limite des stocks disponibles.</p>
            <p>Les photographies du catalogue sont les plus fidèles possibles mais ne peuvent assurer une similitude parfaite avec le produit offert, notamment en ce qui concerne les couleurs.</p>
            <h3>Tarifs</h3>
            <p>Les prix figurant dans le catalogue sont des prix TTC en euros tenant compte de la TVA applicable au jour de la commande. Tout changement du taux pourra être répercuté sur le prix des produits ou des services.</p>
            <p>La Brewlangerie se réserve de modifier ses prix à tout moment, étant toutefois entendu que le prix figurant au catalogue le jour de la commande sera le seul applicable à l’acheteur.</p>
            <p>Les prix indiqués comprennent les frais de traitement de commandes, de transport et de livraison pour autant qu’elles aient lieu dans les zones géographiques prévues ci-après.</p>
            <p>La livraison s’effectue dans le point de dépôt (point relais) choisi par l’acheteur.</p>
            <h3>Commandes</h3>
            <p>L’acheteur, qui souhaite acheter un produit ou un service, doit obligatoirement : remplir la fiche d’identification sur laquelle il indiquera toutes les coordonnées demandées ; remplir le bon de commande en ligne en donnant toutes les références des produits ou services choisis ; valider sa commande après l’avoir vérifiée ; effectuer le paiement dans les conditions prévues.</p>
            <p>La confirmation de la commande entraîne acceptation des présentes conditions de vente, la reconnaissance d’en avoir parfaite connaissance et la renonciation à se prévaloir de ses propres conditions d’achat ou d’autres conditions.</p>
            <p>L’ensemble des données fournies et la confirmation enregistrée vaudront preuve de la transaction. La confirmation vaudra signature et acceptation des opérations effectuées.</p>
            <p>Le vendeur communiquera par courrier électronique confirmation de la commande enregistrée.</p>
            <h3>Rétractation</h3>
            <p>Les acheteurs, personnes physiques non professionnelles, bénéficient d’un délai de rétractation de sept jours à compter de la livraison de leur commande pour faire retour du produit au vendeur pour échange ou remboursement sans pénalité, à l’exception des frais de retour.<br />
            Ce délai est réduit à 24h pour le pain, étant une denrée périssable rapidement perdue.</p>
            <p>Tout produit ouvert ou consommé, même partiellement, ne sera pas ni repris ni échangé.</p>
            <h3>Modalités de paiement</h3>
            <p>Le montant total est exigible à la commande.</p>
            <p>Les paiements seront effectués par carte bancaire; ils seront réalisés par le biais du système sécurisé Stripe qui utilise le protocole SSL de telle sorte que les informations transmises sont cryptées par un logiciel et qu’aucun tiers ne peut en prendre connaissance au cours du transport sur le réseau.</p>
            <p>Le compte de l’acheteur ne sera débité que du montant des produits ou services envoyés ou téléchargés.</p>
            <p>À la demande de l’acheteur, il lui sera adressée une facture sur papier faisant ressortir la TVA.</p>
            <h3>Archivage - Preuve</h3>
            <p>La Brewlangerie archivera les bons de commandes et les factures sur un support fiable et durable constituant une copie fidèle conformément aux dispositions de l’article 1348 du Code civil. Les registres informatisés de La Brewlangerie seront considérés par les parties comme preuve des communications, commandes, paiements et transactions intervenus entre les parties.</p>
            <h3>Livraison</h3>
            <p>Les livraisons sont faites à l’adresse du point de dépôt (point relais) indiquée dans le bon de commande.</p>
            <p>La date de livraison est celle de la vente choisie par l’acheteur au moment de la commande et est indiquée en haut du bon de commande.</p>
            <p>Les délais de livraison ne sont donnés qu’à titre indicatif.</p>
            <h3>Propriété intellectuelle</h3>
            <p>Tous les éléments du site de La Brewlangerie sont et restent la propriété intellectuelle et exclusive de La Brewlangerie. Personne n’est autorisé à reproduire, exploiter, rediffuser, ou utiliser à quelque titre que ce soit, même partiellement, des éléments du site qu’ils soient logiciels, visuels ou sonores.</p>
            <h3>Règlement des litiges</h3>
            <p>Les présentes conditions de vente en ligne sont soumises à la loi française. En cas de litige, compétence est attribuée aux tribunaux compétents, nonobstant pluralité de défendeurs ou appel en garantie.</p>
          </section>
        </main>
      </div>
    )
  }
}

export default Legal
